@import "~antd/dist/antd.less";

// @font-face {
//     font-family: 'Libre Franklin';
//     src: url('./static/fonts/LibreFranklin/LibreFranklin-Medium.ttf');
//     font-style: normal;
// }

@font-face {
  font-family: "Montserrat";
  src: url("./static/fonts/Montserrat/Montserrat-Medium.ttf");
  font-style: normal;
}

// .ant-layout-content {
//   margin-top: 70px;
// }

body {
  font-family: "Montserrat";
}

.banner-title {
  font-family: "Montserrat";
}

.ant-layout {
  background: white;
}

.nav-drawer-menu {
  .ant-menu-title-content {
    font-size: x-large;
  }
}

@media screen and (max-width: 480px) {
  #banner h1 {
    color: white;
    font-size: 40px !important;
  }
  #banner h2 {
    color: white;
    font-size: 34px !important;
  }
  #banner h3 {
    color: white;
    font-size: 22px !important;
  }
}

@media screen and (max-width: 576px) {
  #banner h1 {
    color: white;
    font-size: 40px !important;
  }
  #banner h2 {
    color: white;
    font-size: 34px !important;
  }
  #banner h3 {
    color: white;
    font-size: 22px !important;
  }
}

@media screen and (max-width: 768px) {
  #banner h1 {
    color: white;
    font-size: 40px !important;
  }
  #banner h2 {
    color: white;
    font-size: 34px !important;
  }
  #banner h3 {
    color: white;
    font-size: 22px !important;
  }
}

@media screen and (min-width: 976px) {
  #banner h1 {
    color: white;
    font-size: 60px !important;
  }
  #banner h2 {
    color: white;
    font-size: 40px !important;
  }
}

#listing-create h1 {
  font-size: 60px !important;
}

#listing-create h2 {
  font-size: 38px !important;
}

#agent-info h1 {
  font-size: 60px !important;
}

#agent-info h2 {
  font-size: 38px !important;
}

#buyer-reg-info h1 {
  font-size: 60px !important;
}

#buyer-reg-info h2 {
  font-size: 38px !important;
}

#my-buyers h1 {
  font-size: 60px !important;
}

#my-buyers h2 {
  font-size: 38px !important;
}

#dashboard h1 {
  font-size: 60px !important;
}

#help-info h1 {
  font-size: 60px !important;
}

#terms h1 {
  font-size: 60px !important;
}

#contact-us h1 {
  font-size: 60px !important;
}

#resources-cards h1 {
  font-size: 60px !important;
}

#resources-cards h2 {
  font-size: 38px !important;
}

#training h1 {
  font-size: 60px !important;
}

#training h2 {
  font-size: 38px !important;
}

#resources-cards .ant-card-head {
  min-height: 48px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 30px;
  background: transparent;
  border: 1px solid black;
  border-radius: 2px 2px 0 0;
  border-color: "black";
}

#resources-cards .ant-card-body {
  min-height: 48px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border: 1px solid black;
  border-radius: 2px 2px 0 0;
  border-color: "black";
}

// h1 {
//   /* Extra small devices (phones, less than 768px) */
//   font-size: 30px;

//   /* Small devices (tablets, 768px and up) */
//   @media (min-width: 768px) {
//     font-size: 36px;
//   }

//   /* Medium devices (desktops, 992px and up) */
//   @media (min-width: 992px) {
//     font-size: 36px;
//   }

//   /* Large devices (large desktops, 1200px and up) */
//   @media (min-width: 1200px) {
//     font-size: 36px;
//   }
// }

.ant-divider {
  background-color: #a9a9a9;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  font-size: 24px;
  font-weight: bolder;
}

.content3-title {
  font-size: 24px;
}

.home-page.content3 {
  padding-top: 0;
}

.banner-content h1 {
  font-size: 96px;
  color: white;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.ant-collapse-content-box {
  padding: 16px;
  font-weight: lighter;
  font-size: 18px;
}

.carousel .control-dots .dot {
  background: #000;
}

.agent-photo {
  object-fit: cover;
  border-radius: 50%;
}

.listing-countdown .ant-statistic-title {
  font-weight: bold;
  font-size: 20px;
  color: red;
  font-family: Arial, Helvetica, sans-serif;
}

.listing-countdown .ant-statistic-content-value {
  font-weight: bold;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

// .ant-upload-list-picture .ant-upload-list-item-thumbnail img, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
//   width: 200px;
//   height: 200px;
// }

// .ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
//   height: 200px;
// }

// .ant-upload-list-picture .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {

//   width: 200px;
//   height: 200px;
// }

// .ant-upload-list-text .ant-upload-list-item-name, .ant-upload-list-picture .ant-upload-list-item-name {
//   font-size: 22px;
// }

.ant-upload-draggable-list-item {
  border-left: 2px dashed rgba(0, 0, 0, 0);
  border-right: 2px dashed rgba(0, 0, 0, 0);
}
.ant-upload-draggable-list-item.drop-over-downward {
  border-right-color: #1890ff;
}

.feature-image {
  border: 1px solid #1da57a !important;
}

.ant-upload-draggable-list-item.drop-over-upward {
  border-left-color: #1890ff;
}

.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin-top: 0px;
}

#rc-tabs-0-tab-Offers {
  font-size: 20px;
  font-weight: 500;
}

#rc-tabs-0-tab-Buyers {
  font-size: 20px;
  font-weight: 500;
}

.ant-tabs-tab-btn {
  font-size: 20px;
  font-weight: 500;
}

.ant-table-column-title {
  font-size: 18px;
}

#actions-tab-title {
  font-size: 18px;
}

#actions-tab-title-user {
  font-size: 18px;
}

#actions-tab-title-offer {
  font-size: 18px;
}

#agent-info-title {
  font-size: 18px;
}

.ant-table-thead > tr > th {
  font-size: 18px;
  font-weight: 600;
}

.ant-tabs-nav {
  width: "100px";
}

.ant-menu-title-content {
  font-size: large;
}

.ant-menu-item.ant-menu-item-only-child {
  font-size: large;
  font-weight: bold;
}

.customPanel {
  .ant-collapse-header {
    margin-top: 10px;
    padding: 0 0 4px 0 !important;
  }
}

.customPanel {
  .ant-collapse-content-box {
    padding: 0 0 0 30px !important;
  }
}

// NAV BAR

.logo {
  float: left;
  height: 60px;
  width: 160px;
  top: 0;
  left: 0;
  margin-top: 5px;
  background-image: url("https://static-assets-platform.s3.amazonaws.com/logo-small.png");
}

.main-header {
  box-shadow: 0 3px 5px rgb(57 63 72 / 30%);
  padding-left: 5%;
  padding-right: 0%;
}

.menuContainer {
  float: right;
}

.mainMenu {
  float: right;
}

.barsMenu {
  float: right;
  display: none;
  margin-top: 15px;
  height: 32px;
  padding: 6px;
  color: #000000;
}

// @media screen and (max-width: 1060px) {
//   .main-header {
//     padding: 0;
//   }
// }

@media screen and (max-width: 1310px) {
  .main-header {
    padding: 0;
  }
}

@media screen and (max-width: 950px) {
  .barsMenu {
    display: inline-block;
  }

  .mainMenu {
    display: none;
  }
}

.my-listings-card-title {
  color: #1da57a;
  cursor: pointer;
}

.my-listings-card-title:hover {
  color: #157557;
}

#dashboard-cards .ant-card-meta .ant-card-meta-detail .ant-card-meta-title {
  color: #fff;
  text-align: center;
  align-items: center;
  font-size: 24px;
}

#dashboard-cards
  .ant-card-meta
  .ant-card-meta-detail
  .ant-card-meta-description {
  color: #fff;
  text-align: center;
  align-items: center;
  font-size: 14px;
}

#dashboard-cards .ant-card-body {
  background-color: #1da57a;
}

#dashboard-cards .ant-card.ant-card-bordered.ant-card-hoverable {
  width: 260px;
  border-width: 2px;
}

#dashboard-cards
  .ant-card.ant-card-bordered.ant-card-hoverable
  .ant-card-cover {
  padding: 40px;
}

#buyer-cards .ant-card-meta-description {
  color: #000;
}

#buyer-dashboard-cards
  .ant-card-meta
  .ant-card-meta-detail
  .ant-card-meta-title {
  color: #fff;
  text-align: center;
  align-items: center;
  font-size: 24px;
}

#buyer-dashboard-cards
  .ant-card-meta
  .ant-card-meta-detail
  .ant-card-meta-description {
  color: #fff;
  text-align: center;
  align-items: center;
  font-size: 14px;
}

#buyer-dashboard-cards .ant-card-body {
  background-color: #1da57a;
}

#buyer-dashboard-cards .ant-card.ant-card-bordered.ant-card-hoverable {
  width: 260px;
  border-width: 2px;
}

#buyer-dashboard-cards
  .ant-card.ant-card-bordered.ant-card-hoverable
  .ant-card-cover {
  padding: 40px;
}

#make-offer-modal {
  font-size: 40px;
}

@primary-color: #1DA57A;