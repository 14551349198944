/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Libre Franklin',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.carousel .thumbs {
  text-align: center;
  padding-left: 0;
  margin-left: 3px;
  margin-right: 3px;
}

.signin-popover {
  width: 15%;
}
/* 
p {
  font-family: 'Libre Franklin';
  font-weight: 100;
} */

.ant-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.ant-col:first-child {
  padding-left: 0 !important;
}

.ant-col:last-child {
padding-right: 0 !important;
}


.required-label:after {
  content: "*";
  color: red;
}

